var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var QH,SH,UH,cI,fI,kI;$CLJS.PH=new $CLJS.M(null,"get-month","get-month",-369374731);QH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.RH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);SH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.TH=new $CLJS.M(null,"iso","iso",-1366207543);UH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.VH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.WH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.XH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.YH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.ZH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.$H=new $CLJS.M(null,"us","us",746429226);$CLJS.aI=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.bI=new $CLJS.M(null,"now","now",-1650525531);
cI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.dI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.eI=new $CLJS.M(null,"get-day","get-day",127568857);fI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.gI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.hI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.iI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.jI=new $CLJS.M(null,"get-hour","get-hour",622714059);kI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.lI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.mI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.nI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.oI=new $CLJS.M(null,"target","target",253001721);$CLJS.wE($CLJS.YA,$CLJS.H([$CLJS.Zr,$CLJS.$B,$CLJS.pr,$CLJS.$F]));$CLJS.KD.m(null,$CLJS.YH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.LD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dk,null,$CLJS.Gk,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.pE($CLJS.YH,$CLJS.bE);
for(var pI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,$CLJS.dB],null)),qI=null,rI=0,sI=0;;)if(sI<rI){var tI=qI.X(null,sI);$CLJS.wE(tI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$F],null)]));$CLJS.pE(tI,$CLJS.YH);sI+=1}else{var uI=$CLJS.y(pI);if(uI){var vI=uI;if($CLJS.vd(vI)){var wI=$CLJS.ic(vI),xia=$CLJS.jc(vI),yia=wI,zia=$CLJS.D(wI);pI=xia;qI=yia;rI=zia}else{var xI=$CLJS.z(vI);$CLJS.wE(xI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$F],null)]));$CLJS.pE(xI,$CLJS.YH);pI=$CLJS.B(vI);qI=null;rI=0}sI=0}else break}
for(var yI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.WH,$CLJS.PH,$CLJS.eI,$CLJS.jI,$CLJS.gI,$CLJS.VH,$CLJS.lI],null)),zI=null,AI=0,BI=0;;)if(BI<AI){var Aia=zI.X(null,BI);$CLJS.wE(Aia,$CLJS.H([$CLJS.Zr,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));BI+=1}else{var CI=$CLJS.y(yI);if(CI){var DI=CI;if($CLJS.vd(DI)){var EI=$CLJS.ic(DI),Bia=$CLJS.jc(DI),Cia=EI,Dia=$CLJS.D(EI);yI=Bia;zI=Cia;AI=Dia}else{var Eia=$CLJS.z(DI);$CLJS.wE(Eia,$CLJS.H([$CLJS.Zr,
$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));yI=$CLJS.B(DI);zI=null;AI=0}BI=0}else break}$CLJS.wE($CLJS.XA,$CLJS.H([$CLJS.Zr,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)]));
for(var FI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.lI,null,$CLJS.gI,null,$CLJS.jI,null,$CLJS.hI,null,$CLJS.VH,null,$CLJS.PH,null,$CLJS.WH,null,$CLJS.eI,null],null),null)),GI=null,HI=0,II=0;;)if(II<HI){var Fia=GI.X(null,II);$CLJS.wE(Fia,$CLJS.H([$CLJS.Zr,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));II+=1}else{var JI=$CLJS.y(FI);if(JI){var KI=JI;if($CLJS.vd(KI)){var LI=$CLJS.ic(KI),Gia=$CLJS.jc(KI),Hia=LI,Iia=$CLJS.D(LI);
FI=Gia;GI=Hia;HI=Iia}else{var Jia=$CLJS.z(KI);$CLJS.wE(Jia,$CLJS.H([$CLJS.Zr,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));FI=$CLJS.B(KI);GI=null;HI=0}II=0}else break}$CLJS.Y(kI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.TH,$CLJS.$H,$CLJS.dI],null));
$CLJS.yE($CLJS.$A,$CLJS.H([$CLJS.Zr,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.iI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.pD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ar,new $CLJS.h(null,2,[$CLJS.ts,"valid timezone ID",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.fj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.OH.tz.names())),$CLJS.FH],null)],null));
$CLJS.yE($CLJS.RH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iI],null)],null)],null)],
null)]));$CLJS.pE($CLJS.RH,$CLJS.YH);$CLJS.wE($CLJS.bI,$CLJS.H([$CLJS.Zr,$CLJS.Fk]));$CLJS.Y(UH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,$CLJS.Gk],null))}],null)],null));
$CLJS.Y(QH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UH],null)],null)],null)],null));
$CLJS.IF.g($CLJS.XH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.XH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UF],null)],null)],null)],null)],null)],null));
$CLJS.KD.m(null,$CLJS.XH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Gk:$CLJS.n($CLJS.oE($CLJS.ZF,a))?$CLJS.Dk:$CLJS.Gk:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.zH,b))?$CLJS.Dk:$CLJS.n($CLJS.dh($CLJS.AH,b))?$CLJS.Dk:null:null;if($CLJS.n(a))return a;b=$CLJS.LD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.tj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(fI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.Qu],null),$CLJS.pr],null));
$CLJS.yE($CLJS.ZA,$CLJS.H([$CLJS.Zr,$CLJS.Gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$F],null)],null)],null)],null)]));
$CLJS.wE($CLJS.Fx,$CLJS.H([$CLJS.Zr,$CLJS.Kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YF],null)]));$CLJS.Y(SH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ar,$CLJS.WF,$CLJS.ki,$CLJS.Dj,$CLJS.ZH,$CLJS.mI,$CLJS.nI,$CLJS.ij,$CLJS.pi,$CLJS.gi,$CLJS.Ri,$CLJS.TF],null));$CLJS.Y(cI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.TH,$CLJS.$H,$CLJS.dI],null));
$CLJS.yE($CLJS.bB,$CLJS.H([$CLJS.Zr,$CLJS.$i,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cI],null)],null)],
null)],null)]));