var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var qY,zoa,Aoa,Boa,Coa,xY,Doa,Eoa,yY,Foa,AY,Goa,Hoa,Ioa,FY,GY,Joa,Koa,OY,Loa,Moa,Noa,TY,Ooa;qY=function(){};$CLJS.rY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(qY,a):$CLJS.Ya(qY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.sY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.tY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.uY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.vY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.wY=new $CLJS.M(null,"search","search",1564939822);xY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
yY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.zY=new $CLJS.M(null,"dataset","dataset",1159262238);AY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.BY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.CY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.DY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.EY=new $CLJS.M("source","joins","source/joins",1225821486);
FY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);GY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.HY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.IY=new $CLJS.M(null,"selected?","selected?",-742502788);Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.JY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.KY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.LY=new $CLJS.M(null,"active","active",1895962068);$CLJS.MY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.NY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
OY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.PY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.QY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.RY=new $CLJS.M("source","native","source/native",-1444604147);
Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.SY=new $CLJS.M(null,"auto-list","auto-list",314197731);TY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.UY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.VY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.WY=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(FY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ar,$CLJS.KY,$CLJS.RY,$CLJS.tY,$CLJS.vY,$CLJS.PY,$CLJS.jK,$CLJS.bK,$CLJS.EY,$CLJS.BY,$CLJS.DY],null));$CLJS.Y(yY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ar],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.SY,null,$CLJS.wY,null,$CLJS.ej,null,$CLJS.cz,null],null),null))));
$CLJS.Y(OY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.iK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.TJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UJ,$CLJS.yD],null)],null));
$CLJS.Y(xY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.dK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.TJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.ur],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.ur],null)],null)],null));
$CLJS.Y($CLJS.SJ,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.LJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.vj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.yD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.cr,$CLJS.vj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.zD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.yD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.yD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VY,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.VJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,new $CLJS.h(null,2,[$CLJS.si,1,$CLJS.Bj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.QY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xY],null)],null)],
null)],null));$CLJS.Y(TY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JY,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null)],null)],null)],null));
$CLJS.Y(AY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LY,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JY,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
$CLJS.pD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.hK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.VJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uY,$CLJS.WJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Aj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NY,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.Aj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.OJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,AY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.NJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.hD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$J,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Aj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.PJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.fD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$J,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Aj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.RJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.pD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.fK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.WJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Aj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Aj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.WY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Gi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.Gi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.Aj],null)],null)],null));
$CLJS.Y(GY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.rY},Coa,$CLJS.zg([$CLJS.Fi,$CLJS.U,$CLJS.Gj,$CLJS.fi,$CLJS.Bi,$CLJS.Ui,$CLJS.Qj,$CLJS.ei,$CLJS.Cj,$CLJS.Fj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ep],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.rY)?$CLJS.rY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GY],null)],null)],null)],null));