var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var L3,N3,P3,Q3,R3,S3,T3,Era;L3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=L3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=L3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.M3=function(a,b){return L3($CLJS.YZ(a),b)};
N3=function(a,b,c){var d=$CLJS.CV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.ZE);d=$CLJS.sG($CLJS.gk.j($CLJS.Tg([c]),$CLJS.wD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.dD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.sr,c,$CLJS.FE,a,$CLJS.DK,b],null));return d};$CLJS.O3=function(a){return $CLJS.C1($CLJS.vF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};P3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
Q3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);R3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);S3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);T3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Era=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.K_.m(null,$CLJS.ZE,function(a,b){var c=$CLJS.Be($CLJS.ZE.h($CLJS.CV(a,b)));return $CLJS.n(c)?$CLJS.F1($CLJS.ID("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.g_.v(a,b,v,$CLJS.h_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.g_.v(a,
b,t,$CLJS.h_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.N_.m(null,$CLJS.ZE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tA);e=$CLJS.J.g(e,$CLJS.oi);c=$CLJS.I(c,2,null);c=N3(a,b,c);return $CLJS.yk.l($CLJS.H([$CLJS.e_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.MJ,$CLJS.jK,$CLJS.y0,$CLJS.wD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.oi,e],null):null]))});
$CLJS.H_.m(null,$CLJS.ZE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.g_.v(a,b,N3(a,b,c),d)});$CLJS.pE(R3,T3);
for(var U3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.kF,$CLJS.sF],null)),V3=null,W3=0,X3=0;;)if(X3<W3){var Fra=V3.X(null,X3);$CLJS.pE(Fra,R3);X3+=1}else{var Y3=$CLJS.y(U3);if(Y3){var Z3=Y3;if($CLJS.vd(Z3)){var $3=$CLJS.ic(Z3),Gra=$CLJS.jc(Z3),Hra=$3,Ira=$CLJS.D($3);U3=Gra;V3=Hra;W3=Ira}else{var Jra=$CLJS.z(Z3);$CLJS.pE(Jra,R3);U3=$CLJS.B(Z3);V3=null;W3=0}X3=0}else break}
$CLJS.H_.m(null,R3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.g_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.dD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.dD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.ID("Count");case "cum-count":return $CLJS.ID("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.I_.m(null,R3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.N_.m(null,S3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.lH($CLJS.N_,T3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.sj,$CLJS.RB)});
$CLJS.pE(S3,T3);$CLJS.pE(R3,S3);$CLJS.pE($CLJS.OE,S3);$CLJS.H_.m(null,$CLJS.SE,function(){return $CLJS.ID("Case")});$CLJS.I_.m(null,$CLJS.SE,function(){return"case"});$CLJS.pE(Q3,T3);
for(var a4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dF,$CLJS.EF,$CLJS.OE,$CLJS.Bj,$CLJS.wF,$CLJS.si,$CLJS.xF,$CLJS.vF,$CLJS.PE],null)),b4=null,c4=0,d4=0;;)if(d4<c4){var Kra=b4.X(null,d4);$CLJS.pE(Kra,Q3);d4+=1}else{var e4=$CLJS.y(a4);if(e4){var f4=e4;if($CLJS.vd(f4)){var g4=$CLJS.ic(f4),Lra=$CLJS.jc(f4),Mra=g4,Nra=$CLJS.D(g4);a4=Lra;b4=Mra;c4=Nra}else{var Ora=$CLJS.z(f4);$CLJS.pE(Ora,Q3);a4=$CLJS.B(f4);b4=null;c4=0}d4=0}else break}
$CLJS.I_.m(null,Q3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.H_.m(null,Q3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.g_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.dD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.dD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.dD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.dD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.dD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.dD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.dD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.dD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.dD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.H_.m(null,$CLJS.VE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.dD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.g_.v(a,b,e,d)]))});$CLJS.I_.m(null,$CLJS.VE,function(){return"percentile"});
$CLJS.pE(P3,T3);for(var h4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VE,$CLJS.PE],null)),i4=null,j4=0,k4=0;;)if(k4<j4){var Pra=i4.X(null,k4);$CLJS.pE(Pra,P3);k4+=1}else{var l4=$CLJS.y(h4);if(l4){var m4=l4;if($CLJS.vd(m4)){var n4=$CLJS.ic(m4),Qra=$CLJS.jc(m4),Rra=n4,Sra=$CLJS.D(n4);h4=Qra;i4=Rra;j4=Sra}else{var Tra=$CLJS.z(m4);$CLJS.pE(Tra,P3);h4=$CLJS.B(m4);i4=null;j4=0}k4=0}else break}
$CLJS.N_.m(null,P3,function(a,b,c){var d=$CLJS.Zj.g,e=$CLJS.lH($CLJS.N_,T3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Zj,a,$CLJS.sj)});$CLJS.H_.m(null,$CLJS.CF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.dD("Sum of {0} matching condition",$CLJS.H([$CLJS.g_.v(a,b,e,d)]))});
$CLJS.I_.m(null,$CLJS.CF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.J_.j(a,b,d))].join("")});$CLJS.pE($CLJS.CF,T3);$CLJS.H_.m(null,$CLJS.qF,function(){return $CLJS.ID("Share of rows matching condition")});$CLJS.I_.m(null,$CLJS.qF,function(){return"share"});$CLJS.N_.m(null,$CLJS.qF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.lH($CLJS.N_,T3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.sj,$CLJS.iC)});
$CLJS.pE($CLJS.qF,T3);$CLJS.H_.m(null,$CLJS.sF,function(){return $CLJS.ID("Count of rows matching condition")});$CLJS.N_.m(null,T3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.yk.l;d=$CLJS.n(d)?$CLJS.Bl($CLJS.e_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.sj],null)):null;var f=$CLJS.lH($CLJS.N_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.yk,$CLJS.H([d,a]))});
$CLJS.Ura=function(){function a(d){return $CLJS.C1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.C1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.lV.m(null,$CLJS.ZE,function(a){return a});
$CLJS.o4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.WA(f),$CLJS.PJ))f=$CLJS.nV(f);else return $CLJS.H1(d,e,$CLJS.ZE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.p4=function(){function a(d,e){return $CLJS.Be($CLJS.ZE.h($CLJS.CV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.q4=function(){function a(d,e){var f=$CLJS.Be($CLJS.ZE.h($CLJS.CV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.e_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.B1(l,$CLJS.oi,$CLJS.n(t)?t:$CLJS.dj),$CLJS.MJ,$CLJS.jK,$CLJS.H([$CLJS.y0,$CLJS.wD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.H_.m(null,$CLJS.QF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.MF);return $CLJS.kD.h(a.o?a.o():a.call(null))});$CLJS.O_.m(null,$CLJS.QF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.cF);b=$CLJS.J.g(d,$CLJS.MF);c=$CLJS.J.g(d,$CLJS.LF);d=$CLJS.J.g(d,$CLJS.IY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.F_,$CLJS.$z(a),$CLJS.H([$CLJS.D_,c]));return null!=d?$CLJS.R.j(a,$CLJS.o_,d):a});
$CLJS.Vra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.U0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.CV(d,e),l=$CLJS.T_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.gk.j($CLJS.ef(function(m){m=$CLJS.OF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.tl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.LF),u=$CLJS.J.g(m,$CLJS.JF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.ur))return $CLJS.R.j(m,$CLJS.zV,l);t=$CLJS.Be($CLJS.t0(function(v){return $CLJS.yZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.zV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.QF)})),$CLJS.RF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Wra=function(){function a(d,e){return $CLJS.mV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF.h(d),$CLJS.N,$CLJS.oV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.LF.h(d)))return $CLJS.mV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF.h(d),$CLJS.N],null));var e=$CLJS.cF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Era,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();