var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.d1=function(a,b){var c=$CLJS.DV(b);return $CLJS.n(c)?$CLJS.a_(a,c):$CLJS.ZZ(a,b)};$CLJS.e1=function(a){var b=$CLJS.WA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.rO.h($CLJS.ED(a));case "metadata/column":return $CLJS.aZ.h(a);case "mbql/join":return $CLJS.VD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.f1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.g1=function(a,b){var c=$CLJS.e1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.ZJ);d=$CLJS.J.g(d,$CLJS.$J);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.d1(a,d),$CLJS.n(d))){d=$CLJS.U.h(d);a=$CLJS.U.h($CLJS.V0(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.f1(a,$CLJS.U.h(b)):$CLJS.U.h(b)};
$CLJS.h1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.$J),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.gk.l($CLJS.ef($CLJS.XJ),$CLJS.ef($CLJS.Ii),$CLJS.ef($CLJS.gk.g($CLJS.Yk,$CLJS.Ii)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.Ii),l=$CLJS.J.g(f,$CLJS.XJ);return $CLJS.R.l($CLJS.V0(a,l),$CLJS.x_,k,$CLJS.H([$CLJS.y_,$CLJS.aZ.h(f)]))}),$CLJS.hk.h(function(f){return $CLJS.Cd(e,$CLJS.$J.h(f))}),$CLJS.cD(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.$J),l=$CLJS.J.g(f,
$CLJS.x_),m=$CLJS.J.g(f,$CLJS.y_);f=$CLJS.ZZ(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.vd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var T=$CLJS.hd(A,X),da=$CLJS.R.l(T,$CLJS.ZJ,l,$CLJS.H([$CLJS.u_,m,$CLJS.MJ,$CLJS.DY,$CLJS.sY,$CLJS.U.h(T)]));T=G;var oa=$CLJS.R,Ma=oa.j,sb=da;da=$CLJS.g1(a,da);da=d.h?d.h(da):d.call(null,da);oa=Ma.call(oa,sb,$CLJS.HY,da);T.add(oa);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var K=$CLJS.z(x),S=$CLJS.R.l(K,$CLJS.ZJ,l,$CLJS.H([$CLJS.u_,m,$CLJS.MJ,$CLJS.DY,$CLJS.sY,$CLJS.U.h(K)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.HY,function(){var X=$CLJS.g1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.S_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.C_,d,$CLJS.w_,!1],null)))})])),c)};$CLJS.i1=function(a,b){return $CLJS.Z0.g(a,b)};$CLJS.j1=function(a){return $CLJS.dD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.k1=function(a,b){var c=$CLJS.RD.h(b);$CLJS.n(c)||(c=$CLJS.NY.h(b),$CLJS.n(c)||(c=$CLJS.MY.h(b),c=null!=c?$CLJS.Q_.h($CLJS.i1(a,$CLJS.UX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.sd(c)?$CLJS.zV.h(c):$CLJS.rd(c)?c:null),$CLJS.n(c)?$CLJS.ik.g($CLJS.Xs(Rpa,a,b),c):null):null};$CLJS.l1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.m1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.H_.m(null,$CLJS.hK,function(a,b,c){return $CLJS.zz($CLJS.kD,$CLJS.U)(c)});$CLJS.N_.m(null,$CLJS.hK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);c=$CLJS.J.g(a,$CLJS.kD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.kD,$CLJS.c1.g($CLJS.b1,b)):a});
$CLJS.S_.m(null,$CLJS.hK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.RD),f=$CLJS.J.g(c,$CLJS.NY);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.w_),l=$CLJS.J.g(d,$CLJS.C_);return $CLJS.bf.g($CLJS.Q_.v(a,b,c,d),$CLJS.n(k)?$CLJS.h1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.K_.m(null,$CLJS.LU,function(a,b){var c=$CLJS.CV(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.LU);if($CLJS.n(c)){var d=$CLJS.a_(a,c);a=$CLJS.n(d)?$CLJS.g_.v(a,b,d,$CLJS.h_):null;return $CLJS.n(a)?a:$CLJS.j1(c)}return null});
var Rpa=function(){function a(d,e,f){var k=$CLJS.Zj.g($CLJS.Ez(f,$CLJS.eA),$CLJS.m1);f=$CLJS.yk.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.dj,$CLJS.Vs,$CLJS.LJ],null),function(){var l=$CLJS.Ii.h(k);if($CLJS.n(l))try{return $CLJS.V0(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.LJ,$CLJS.MJ,$CLJS.KY,$CLJS.sY,$CLJS.zz($CLJS.sY,$CLJS.U)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.UY,$CLJS.R0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.zY.h($CLJS.a_(d,
$CLJS.R0(e)))):!0)?$CLJS.R.j(f,$CLJS.l1,!0):f;return $CLJS.fk.g($CLJS.sj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.XJ,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.P_.m(null,$CLJS.hK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.C_);return $CLJS.ik.g(function(f){var k=$CLJS.pB($CLJS.HY,$CLJS.sY,$CLJS.U)(f);return $CLJS.R.j(f,$CLJS.HY,e.h?e.h(k):e.call(null,k))},$CLJS.k1(a,c))});