var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var apa,bpa,cpa,d0,e0,f0,dpa,epa,fpa,h0,i0,gpa;$CLJS.c0=function(a){var b=$CLJS.dd($CLJS.dh($CLJS.fh(["(.*?)",$CLJS.nH($CLJS.H([$CLJS.tH])),"$"].join("")),a));return $CLJS.n(b)?b:a};apa=function(a,b){return a.isSame(b,"day")};bpa=function(a,b){return a.isSame(b,"month")};cpa=function(a,b){return a.isSame(b,"year")};
d0=function(a){return $CLJS.Me(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.Hk);d=$CLJS.J.g(d,$CLJS.$_);return $CLJS.n($CLJS.dh(b,a))?(b=$CLJS.OH.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null):null):null},$CLJS.$oa)};e0=function(a){return a instanceof Date?$CLJS.OH.utc(a):a};f0=function(a,b,c){b="string"===typeof b?$CLJS.z(d0(b)):e0(b);return("string"===typeof c?$CLJS.z(d0(c)):e0(c)).diff(b,$CLJS.Xg(a))};
dpa=function(a,b){return f0.j?f0.j($CLJS.Lj,a,b):f0.call(null,$CLJS.Lj,a,b)};epa=function(a,b){if("string"===typeof a)return a;a=e0(a);var c=function(){if($CLJS.Dz(b,$CLJS.Ik))return $CLJS.X_;if($CLJS.Dz(b,$CLJS.Kk))return $CLJS.W_;if($CLJS.Dz(b,$CLJS.Fk))return $CLJS.a0;if($CLJS.Dz(b,$CLJS.Gk))return $CLJS.Y_;if($CLJS.Dz(b,$CLJS.Dk))return $CLJS.Z_;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.b0.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,c],null))};
$CLJS.g0=function(a){if($CLJS.n($CLJS.OH.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.OH)($CLJS.c0(a),fpa);throw $CLJS.Uh("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.fj,a],null));};fpa=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];h0=function h0(a,b){if("string"===typeof a){var d=d0(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=h0.g?h0.g(a,b):h0.call(null,a,b);return $CLJS.b0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null))}a=e0(a);return a.startOf($CLJS.Xg(b))};
i0=function i0(a,b,c){if("string"===typeof a){var e=d0(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=i0.j?i0.j(a,b,c):i0.call(null,a,b,c);return $CLJS.b0.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,e],null))}a=e0(a);return a.add(c,$CLJS.Xg(b))};gpa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.j0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.oH,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.k0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g($CLJS.V_,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.hpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(apa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ipa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(bpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.jpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(cpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(dpa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(f0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.l0=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(h0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.mpa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(i0,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.npa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ne.g(epa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.m0=function m0(a){switch(arguments.length){case 1:return m0.h(arguments[0]);case 2:return m0.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.xa("metabase.shared.util.time.coerce_to_timestamp",$CLJS.m0);$CLJS.m0.h=function(a){return $CLJS.m0.g(a,$CLJS.N)};
$CLJS.m0.g=function(a,b){b=$CLJS.yk.l($CLJS.H([$CLJS.N,$CLJS.fA(b)]));if(!$CLJS.n($CLJS.U_(a))){var c=(c="string"===typeof a)?$CLJS.dh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.OH.parseZone(a):"string"===typeof a?$CLJS.pH.g(a,b):$CLJS.qH.g(a,b)}return $CLJS.n(gpa.h(b))?a.local():a};$CLJS.m0.A=2;module.exports={coerce_to_timestamp:$CLJS.m0,coerce_to_time:$CLJS.g0};