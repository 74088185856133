var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var BX,Ona,Pna,Qna,EX,Sna,Tna,Una,Vna,GX,HX,IX,KX,Wna,LX,MX,Xna,Yna,Zna,NX,Rna,OX,PX,$na,RX;BX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.CX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.DX=function(a,b){return $CLJS.OK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.ZE,b),$CLJS.Wr(2,2,c))};Pna=function(a,b){var c=$CLJS.Cl(a,$CLJS.DP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.gs.g?$CLJS.gs.g(c,b):$CLJS.gs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.DP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.JU(a),$CLJS.KU(a))};
EX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dr,null,$CLJS.Qk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?BX(2,c):BX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.tW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.hk.h($CLJS.Xk),l):$CLJS.sd(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.Zj.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return EX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.qK.h(a),b);a:{var d=c,e=$CLJS.ND;$CLJS.ND=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.CX($CLJS.Ti,$CLJS.Lk),$CLJS.ff($CLJS.gk.j(FX,$CLJS.z,$CLJS.Lk),$CLJS.Hr.h($CLJS.DX($CLJS.iV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=EX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.TK($CLJS.DX($CLJS.iV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.sV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var K=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=K}else{G=d;break a}}}finally{$CLJS.ND=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.qK.h(a))-1))return a;b+=1}else a=$CLJS.jk.M(a,$CLJS.qK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.FV();return $CLJS.ik.g(function(c){return $CLJS.E.g($CLJS.VD.h(c),"__join")?$CLJS.jk.j(c,$CLJS.VD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.cO.h(a)?$CLJS.Zj.g($CLJS.R.j(a,$CLJS.LU,$CLJS.DV($CLJS.cO.h(a))),$CLJS.cO):a};GX=function(){return $CLJS.hk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};HX=function(a){return $CLJS.Wf.j($CLJS.N,GX(),a)};
IX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.gk.g(GX(),$CLJS.hk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.oi)})),a))};
KX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.JX),c);a=$CLJS.E.g(a,$CLJS.SE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(IX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.gk.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.EA)}),$CLJS.cf.h($CLJS.JX)),$CLJS.zV.h(a))};
LX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.qK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.JX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.FP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.PN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.lU.h(c)],null)},null,a));return $CLJS.n($CLJS.gP.h(a))?$CLJS.wU(a,new $CLJS.h(null,1,[$CLJS.gP,$CLJS.FE],null)):a};
MX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.QV(a,b,$CLJS.gk.g($CLJS.JX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.QV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kr],null),$CLJS.cf.h($CLJS.JX),e)}):d;return $CLJS.wU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.LU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Zj.g(a,$CLJS.LU),$CLJS.cO,["card__",$CLJS.p.h(b)].join("")):a};
Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);NX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);OX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
PX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.QX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);RX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var FX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.SX=$CLJS.N;$CLJS.TX=$CLJS.N;FX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.fV,null,$CLJS.RD,null,$CLJS.BM,null,$CLJS.XO,null,$CLJS.YE,null,$CLJS.LQ,null,$CLJS.ZE,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.fB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.UX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.WA,eoa,aoa,boa,coa,doa);$CLJS.UX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.mV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.UX),d))}else b=a;return b});$CLJS.UX.m(null,$CLJS.eV,function(a){return a});
$CLJS.UX.m(null,$CLJS.SU,function(a){var b=$CLJS.UX.h($CLJS.ZE.h(a)),c=$CLJS.Be($CLJS.ik.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.wV($CLJS.UX.h(t),u)},$CLJS.XO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.wD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.TX,k=$CLJS.SX;$CLJS.TX=d;$CLJS.SX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.XO,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.J.g(t,u))?t:$CLJS.jk.j(t,u,$CLJS.UX)},l,$CLJS.ak.l(FX,$CLJS.ZE,$CLJS.H([$CLJS.XO])));return $CLJS.n($CLJS.BM.h(m))?$CLJS.jk.j(m,$CLJS.BM,Una):m}finally{$CLJS.SX=k,$CLJS.TX=f}});$CLJS.UX.m(null,$CLJS.ZU,function(a){return Pna(a,function(b){return $CLJS.QV(b,$CLJS.CE,$CLJS.UX)})});
$CLJS.UX.m(null,$CLJS.lK,function(a){a=$CLJS.jk.j($CLJS.jk.j(a,$CLJS.tK,$CLJS.UX),$CLJS.qK,$CLJS.UX);var b=$CLJS.n($CLJS.RD.h(a))?$CLJS.jk.j(a,$CLJS.RD,function(c){return $CLJS.il(c)?$CLJS.ik.g($CLJS.UX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.VD.h(a))?$CLJS.R.j(b,$CLJS.VD,"__join"):b});$CLJS.UX.m(null,$CLJS.nA,function(a){return $CLJS.ik.g($CLJS.UX,a)});
$CLJS.UX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Ti.h(a))?Tna($CLJS.R.j($CLJS.jk.j($CLJS.AV(a),$CLJS.qK,function(b){return $CLJS.ik.g($CLJS.UX,b)}),$CLJS.QX,!0)):$CLJS.gs(a,$CLJS.UX)});$CLJS.UX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.mV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,a,b],null))});
$CLJS.UX.m(null,$CLJS.fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.wU(a,new $CLJS.h(null,3,[$CLJS.bC,$CLJS.tA,$CLJS.BN,$CLJS.sj,$CLJS.TQ,$CLJS.rD],null));var c=$CLJS.R.j;var d=$CLJS.oi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.LD(b));a=c.call($CLJS.R,a,$CLJS.oi,d);return $CLJS.mV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,a,b],null))});
$CLJS.UX.m(null,$CLJS.SE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,$CLJS.Zj.g(c,$CLJS.Oh),$CLJS.ik.g($CLJS.UX,b)],null);b=$CLJS.mV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.UX.h(a)):b});$CLJS.UX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.mV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.UX.m(null,$CLJS.ZE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.TX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.WQ,a],null));return $CLJS.mV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.UX.m(null,$CLJS.sL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.UX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.yk.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.fB],null),$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.JX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.WA,joa,foa,goa,hoa,ioa);
$CLJS.JX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.JX,d);a=IX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.JX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.gs(HX(a),$CLJS.JX):
a;return a});for(var VX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[RX,PX],null)),WX=null,XX=0,YX=0;;)if(YX<XX){var koa=WX.X(null,YX);$CLJS.pE(koa,OX);YX+=1}else{var ZX=$CLJS.y(VX);if(ZX){var $X=ZX;if($CLJS.vd($X)){var aY=$CLJS.ic($X),loa=$CLJS.jc($X),moa=aY,noa=$CLJS.D(aY);VX=loa;WX=moa;XX=noa}else{var ooa=$CLJS.z($X);$CLJS.pE(ooa,OX);VX=$CLJS.B($X);WX=null;XX=0}YX=0}else break}
for(var bY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.dF,$CLJS.sF,$CLJS.OE,$CLJS.Bj,$CLJS.wF,$CLJS.si,$CLJS.VE,$CLJS.qF,$CLJS.xF,$CLJS.vF,$CLJS.CF],null)),cY=null,dY=0,eY=0;;)if(eY<dY){var poa=cY.X(null,eY);$CLJS.pE(poa,RX);eY+=1}else{var fY=$CLJS.y(bY);if(fY){var gY=fY;if($CLJS.vd(gY)){var hY=$CLJS.ic(gY),qoa=$CLJS.jc(gY),roa=hY,soa=$CLJS.D(hY);bY=qoa;cY=roa;dY=soa}else{var toa=$CLJS.z(gY);$CLJS.pE(toa,RX);bY=$CLJS.B(gY);cY=null;dY=0}eY=0}else break}
for(var iY=$CLJS.y($CLJS.tf([$CLJS.Iq,$CLJS.Zr,$CLJS.Jq,$CLJS.DF,$CLJS.SE,$CLJS.mF,$CLJS.JE,$CLJS.FF,$CLJS.AE,$CLJS.XE,$CLJS.EE,$CLJS.hF,$CLJS.oF,$CLJS.GE,$CLJS.YA,$CLJS.ZA,$CLJS.Fx,$CLJS.XH,$CLJS.bI,$CLJS.RH,$CLJS.$A,$CLJS.WH,$CLJS.PH,$CLJS.eI,$CLJS.jI,$CLJS.gI,$CLJS.VH,$CLJS.lI,$CLJS.aB,$CLJS.dB,$CLJS.IE,$CLJS.BE,$CLJS.GF,$CLJS.XG,$CLJS.lF,$CLJS.Pw,$CLJS.yF,$CLJS.AF,$CLJS.HF,$CLJS.js,$CLJS.ps],!0)),jY=null,kY=0,lY=0;;)if(lY<kY){var uoa=jY.X(null,lY);$CLJS.pE(uoa,PX);lY+=1}else{var mY=$CLJS.y(iY);
if(mY){var nY=mY;if($CLJS.vd(nY)){var oY=$CLJS.ic(nY),voa=$CLJS.jc(nY),woa=oY,xoa=$CLJS.D(oY);iY=voa;jY=woa;kY=xoa}else{var yoa=$CLJS.z(nY);$CLJS.pE(yoa,PX);iY=$CLJS.B(nY);jY=null;kY=0}lY=0}else break}$CLJS.JX.m(null,OX,function(a){return KX(a)});$CLJS.JX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.gk.g(GX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.JX.h(b)],null)})),a)});
$CLJS.JX.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=IX(b);b=$CLJS.Q;var e=$CLJS.SX,f=$CLJS.J.j(e,c,NX);if($CLJS.E.g(f,NX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.ZE,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sL,k,d],null):k}catch(l){throw c=l,d=$CLJS.qU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.JX.m(null,$CLJS.nA,function(a){return $CLJS.ik.g($CLJS.JX,a)});
$CLJS.JX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.JX.h(a),IX(b)],null)});
$CLJS.JX.m(null,$CLJS.fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=IX($CLJS.wU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.bC,$CLJS.sj,$CLJS.BN,$CLJS.rD,$CLJS.TQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,a,b],null)});
$CLJS.JX.m(null,$CLJS.lK,function(a){var b=HX(a);a=0==$CLJS.VD.h(a).lastIndexOf("__join",0)?$CLJS.Zj.g(b,$CLJS.VD):b;return $CLJS.yk.l($CLJS.H([$CLJS.gs($CLJS.Zj.l(a,$CLJS.qK,$CLJS.H([$CLJS.tK])),$CLJS.JX),MX($CLJS.Bl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tK],null)),$CLJS.tK,$CLJS.NQ),LX(a)]))});
$CLJS.JX.m(null,$CLJS.SU,function(a){var b=$CLJS.ZE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.TX,e=$CLJS.SX;$CLJS.TX=c;$CLJS.SX=b;try{return $CLJS.cb(function(f,k){return $CLJS.QV(f,
k,$CLJS.JX)},MX($CLJS.QV($CLJS.QV(Xna(HX(a)),$CLJS.ZE,function(f){return $CLJS.ik.g(KX,f)}),$CLJS.XO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.JX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vV(C),$CLJS.E.g($CLJS.fj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.JX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vV(x),$CLJS.E.g($CLJS.fj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.fV,$CLJS.NE),$CLJS.ak.l(FX,$CLJS.ZE,$CLJS.H([$CLJS.fV,$CLJS.XO])))}finally{$CLJS.SX=e,$CLJS.TX=d}});$CLJS.JX.m(null,$CLJS.ZU,function(a){return $CLJS.gs(HX(a),$CLJS.JX)});
$CLJS.JX.m(null,$CLJS.eV,function(a){try{var b=HX(a),c=$CLJS.sM.h(b),d=LX(b),e=$CLJS.E.g($CLJS.Vs.h($CLJS.ed($CLJS.qK.h(a))),$CLJS.ZU)?$CLJS.gP:$CLJS.FE;return $CLJS.yk.l($CLJS.H([$CLJS.Zj.l(b,$CLJS.qK,$CLJS.H([$CLJS.sM,$CLJS.QX])),function(){var k=$CLJS.Ae([$CLJS.Ti,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.sM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.qU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.FE,a],null),f);}});
$CLJS.pY=function(){function a(d,e,f){f=$CLJS.AX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.ji,!0])));var k=$CLJS.CV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.ZE);k=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.Dl(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.TX,t=$CLJS.SX;$CLJS.TX=k;$CLJS.SX=l;try{try{return $CLJS.UX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.qU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.FE,d,$CLJS.DK,e,Zna,f,Yna,$CLJS.TX],null),u);}}finally{$CLJS.SX=t,$CLJS.TX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();